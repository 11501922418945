import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import TableOfContents from "./tableOfContents";
import Layout from "./layout";

function getTableOfContent(pageContext, allMdx, isArticle) {
  let tableOfContents = "";
  allMdx.nodes.forEach((element) => {
    if (
      element.frontmatter.title === pageContext.frontmatter.title &&
      element.frontmatter.description === pageContext.frontmatter.description &&
      element.frontmatter.order === pageContext.frontmatter.order
    ) {
      tableOfContents = isArticle ? (
        <TableOfContents content={element.tableOfContents}></TableOfContents>
      ) : (
        ""
      );
    }
  });
  return tableOfContents;
}

const MdxLayout = ({ children, location, pageContext }) => {
  const data = useStaticQuery(graphql`
    query mdxLayoutQuery {
      allMdx(sort: { fields: slug }) {
        nodes {
          slug
          tableOfContents
          frontmatter {
            order
            title
            description
            menuTitle
            image
          }
        }
      }
    }
  `);

  let title = pageContext.frontmatter.title;
  let menuTitle = pageContext.frontmatter.menuTitle;
  let description = pageContext.frontmatter.description;
  let addCrumbs = pageContext.frontmatter.addCrumbs === 1 ? true : false;
  let isArticle = pageContext.frontmatter.isArticle === 1 ? true : false;
  let tableOfContents = getTableOfContent(pageContext, data.allMdx, isArticle);
  let image = pageContext.frontmatter.image;

  return (
    <Layout
      location={location}
      pageContext={pageContext}
      title={title}
      description={description}
      addCrumbs={addCrumbs}
      isArticle={isArticle}
      tableOfContents={tableOfContents}
      menuTitle={menuTitle}
      image={image}
    >
      {children}
    </Layout>
  );
};

export default MdxLayout;
